/* ESTILOS PERSONALIZADOS - FORA DO TEMA PRINCIPAL */

.ant-descriptions-item-label {
  text-decoration: underline;
  font-weight: 500;
}

.gx-customizer-option {
  top: 380px !important;
}

/* PDF render custom styles*/
.pointer {
  cursor: pointer;
}

.ck-editor__editable_inline {
  min-height: 300px;
}

.break-word {
  word-break: break-word;
}

.customForm .ant-space-item {
  width: 100%;
}
